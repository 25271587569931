import { Component, OnInit } from '@angular/core';
import { PreloaderComponent } from "./component/shared/preloader/preloader.component";
import { environment } from '../environments/environment';

declare var sophia: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'app';

  ngOnInit() {
      sophia.api.setOptions({transport: 'http', uri: environment.api});
  }
}
