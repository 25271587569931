import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currencyFormat',
  pure: false
})
export class CurrencyFormatPipe implements PipeTransform {
  transform(amount: number, toDecimals: number = 6): string {
    if(amount === undefined) {
      return '0.000';
    }

    const roundedToFixed = amount.toFixed(toDecimals);
    const formatedIntegers = roundedToFixed.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    return `${formatedIntegers}`;
  }
}
