import { Directive } from '@angular/core';
import { BlockService } from "../service/block.service";
@Directive({
  selector: '[appInputSearch]'
})
export class InputSearchDirective {

  constructor(private blockService: BlockService) {

    console.log(this.blockService);
  }

}
