import { Component, OnInit, OnDestroy } from '@angular/core';
import { BlockService } from '../../service/block.service';
import { PreloaderService } from '../../service/preloader.service';

declare var sophia: any;

@Component({
  selector: 'app-block-list',
  templateUrl: './block-list.component.html',
  styleUrls: ['./block-list.component.scss']
})
export class BlockListComponent implements OnInit, OnDestroy {

    private searchSubscription;
    private preloaderSubscription;
    public error = false;

    public searchValue: string;
    public blockList: Array<any> = [];
    public staticBlockList: Array<any> = [];

    readonly count: number = 25;
    readonly interval: number = 5000;
    readonly chunk: number = 25;

    public loading = [true, false];
    public autoUpdate = true;
    public sophiaApiInterval;


    private previousBlockId: number = 0;

    constructor(private blockService: BlockService,
                private preloaderService: PreloaderService) {
      this.preloaderService.load.emit(true);
    }

    ngOnInit() {

        this.searchSubscription = this.blockService.search.subscribe((result) => {
            this.searchValue = result;
        });

        // load interval requests
        this.sophiaApiInterval = setInterval(() => this.loadInterval(), this.interval);

        // load first x item
        this.loadInit();

        sophia.api.info((err, res) => {

            console.log('response info', res);

        });

    }

    loadChunk() {
        const self = this;
        let blockId;
        const i = 0;

        blockId = this.blockList[this.blockList.length - 1]['block_height'] - 1;
        self.loading[1] = true;

        return new Promise(function(resolve, reject) {

            const next = (index) => {
                if (index < self.chunk) {
                    sophia.api.getBlock(blockId --, (err, res) => {
                        const o = res;
                        o['block_height'] = blockId + 1;
                        self.blockList.push(o);
                        self.staticBlockList.push(o);

                        index++;
                        next(index);

                        if (index === self.chunk) {
                            self.loading[1] = false;
                        }
                    });

                } else {
                    resolve();
                }
            };
            next(i);
        });

    }

    loadInit() {

        const self = this;
        let blockId;
        const i = 0;

        sophia.api.info((err, res) => {

          this.preloaderService.load.emit(false);

            blockId = res.head_block_number;

            return new Promise(function(resolve, reject) {

                const next = (index) => {
                    if (index < self.count) {
                        // console.log('----------');
                        // console.log('blockId request input', blockId);

                        sophia.api.getBlock(blockId--, (e, r) => {
                            const o = r;
                            o['block_height'] = blockId + 1;
                            // console.log('blockId key', blockId + 1);
                            // console.log('***********');
                            self.blockList.push(o);

                            index++;
                            next(index);

                            if (index === self.count) {
                                self.loading[0] = false;
                            }
                        });

                    } else {
                        resolve();
                    }
                };
                next(i);
            });
        });
    }

    loadInterval() {

        const blockId = this.blockList[0] && this.blockList[0]['block_height'] + 1;
        sophia.api.getBlock(blockId, (e, r) => {

            if (r) {
                const o = r;
                o['block_height'] = blockId;

                this.previousBlockId = blockId;
                this.blockList.unshift(o);
            }

        });

    }

    switchAutoUpdate() {
        Object.assign(this.staticBlockList, this.blockList);
    }

    ngOnDestroy() {
        clearInterval(this.sophiaApiInterval);
    }
}
