import { Component, OnInit, OnChanges } from '@angular/core';
import {ActivatedRoute, Router, NavigationEnd} from "@angular/router";
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UniModalComponent} from '../modal/signature/uni-modal.component';
import { SearchService } from '../../service/search.service';
import {PreloaderService} from "../../service/preloader.service";
import {Util} from '../../util';
import { MatSnackBar } from '@angular/material';

declare var sophia: any;

@Component({
  selector: 'app-block-detail',
  templateUrl: './block-detail.component.html',
  styleUrls: ['./block-detail.component.scss']
})
export class BlockDetailComponent implements OnInit {

  public id: number; // block number
  public loading = true;
  public error = false;

  public data = {
      block_id : null,
      extensions : [],
      previous : null,
      signing_key : null,
      timestamp : null,
      transaction_ids : [],
      transaction_merkle_root : null,
      transactions : [],
      witness : null,
      witness_signature : null
  };

  public searchSubscription;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private modalService: NgbModal,
              private searchService: SearchService,
              private preloaderService: PreloaderService,
              public snackBar: MatSnackBar) {

    this.preloaderService.load.emit(true);

    this.route.params.subscribe(params => {

      this.preloaderService.load.emit(true);

      this.id = params.id


      sophia.api.getBlock(this.id, (err, res) => {
       if (err) {
         this.error = true;
       } else {
        Object.assign(this.data, res);
        console.log('data', this.data);
        this.preloaderService.load.emit(false);
        this.loading = false;
        this.preloaderService.load.emit(false);
        this.error = false;
        }
      });
    });

  }

  ngOnInit() {

  }

  open(data) {
      const modalRef = this.modalService.open(UniModalComponent, { centered: true, size: 'lg' });
      modalRef.componentInstance.data = data;
  }

  copy(text) {
    Util.copyLink(text);
    this.snackBar.open('Copied to clipboard', null, {
      duration: 2000
    });
  }

}
