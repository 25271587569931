import { Component, OnInit, Input } from '@angular/core';
import {NgbModal, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-signature',
  templateUrl: './uni-modal.html',
  styleUrls: ['./uni-modal.component.scss']
})
export class UniModalComponent implements OnInit {

  @Input() data;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

}
