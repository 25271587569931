import { Injectable, Output, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  @Output() search: EventEmitter<string> = new EventEmitter();
  constructor() { }

}
