import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {PreloaderService} from "../../service/preloader.service";
import {UniModalComponent} from "../modal/signature/uni-modal.component";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {Util} from '../../util';
import {MatSnackBar} from '@angular/material';

declare var sophia: any;

@Component({
  selector: 'app-account-detail',
  templateUrl: './account-detail.component.html',
  styleUrls: ['./account-detail.component.scss']
})
export class AccountDetailComponent implements OnInit {

  public name: string;
  public error = false;

  public item = {
    id: 0,
    name: null,
    owner: {
      weight_threshold: 0,
      account_auths: [],
      key_auths: []
    },
    active: {
      weight_threshold: 0,
      account_auths: [],
      key_auths: []
    },
    memo_key: null,
    json_metadata: null,
    voting_proxy: null,
    balance: null,
    vesting_shares: null,
    vesting_withdraw_rate: null,
    to_withdraw: 0,
    witness_votes: [],
    sponsored_accounts: [],
    sponsoring_account: null,
    witness: false,
  };

  public itemHistory = [];

  constructor(private route: ActivatedRoute,
              private router: Router,
              private preloaderService: PreloaderService,
              private modalService: NgbModal,
              public snackBar: MatSnackBar) {

      this.preloaderService.load.emit(true);

      this.route.params.subscribe(params => {
          this.preloaderService.load.emit(true);
          this.name = params.name;

          sophia.api.getAccount(this.name, (err, response) => {

              if (err) {
                this.error = true;
              } else {
                Object.assign(this.item, response[0]);
                console.log('item', this.item);
                this.preloaderService.load.emit(false);
                this.error = false;

                sophia.api.getAccountHistory(this.item.name, -1, 500, (e, r) => {
                  if (e) {
                    // error
                  } else {
                    r.map(item => this.itemHistory.unshift(item[1]));

                    console.log('itemHistory', this.itemHistory);
                    console.log('itemHistory response', r);
                    this.preloaderService.load.emit(false);
                  }
                });
              }

          });

          //sophia.api.getTransaction('8fefb84c604cf0fe9db5bfbc57ad645c66178f17',function(err, response){
          //  console.log('get teansaction', response);
          //});

          sophia.api.getWitness(this.name, (err, response) => {
            console.log('getWitness => name', response);
            if(response){
              this.item.witness = true;
            } else{
              this.item.witness = false;
            }
          });

      });
  }

  ngOnInit() {

    sophia.api.getWitness('sofia', function(err, response) {
      console.log('getWitness => sofia', response);
    });

    sophia.api.getWitness('wsophia4', function(err, response) {
      console.log('getWitness => name', response);
    });



  }

  open(data) {
    const modalRef = this.modalService.open(UniModalComponent, { centered: true, size: 'lg' });
    modalRef.componentInstance.data = data;
  }

  copy(text){
    Util.copyLink(text);
    this.snackBar.open('Copied to clipboard', null, {
      duration: 2000
    });
  }


}
