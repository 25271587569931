import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tokenAmountFormat'
})
export class TokenAmountFormatPipe implements PipeTransform {
  transform(amount: string, toDecimals: number = 2): any {
    if(amount === '') {
      return '0.000';
    }

    const amountSplitBySpace = amount.split(' ');
    const roundedToFixed = parseFloat(amountSplitBySpace[0]).toFixed(toDecimals);
    const formatedDecimals = roundedToFixed.replace(/\B(?=(\d{3})+(?!\d))/g, " ");

    return `${formatedDecimals} ${amountSplitBySpace[1]}`;
  }

}
