import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { UiSwitchModule } from 'ngx-ui-switch';

import { AppComponent } from './app.component';
import { BlockListComponent } from './component/block-list/block-list.component';

import { AppRoutingModule } from './app-routing.module';
import { HeaderComponent } from './component/shared/header/header.component';
import { FooterComponent } from './component/shared/footer/footer.component';
import { InputSearchDirective } from './directive/input-search.directive';
import { SearchComponent } from './component/shared/header/search/search.component';
import { BlockDetailComponent } from './component/block-detail/block-detail.component';
import { UniModalComponent } from './component/modal/signature/uni-modal.component';
import { AccountDetailComponent } from './component/account-detail/account-detail.component';
import { TransactionDetailComponent } from './component/transaction-detail/transaction-detail.component';
import { AccountListComponent } from './component/account-list/account-list.component';
import { TokenAmountFormatPipe } from './pipe/token-amount-format.pipe';
import { CurrencyFormatPipe } from './pipe/currency-format.pipe';
import { PreloaderComponent } from './component/shared/preloader/preloader.component';
import { ErrorPageComponent } from './component/error-page/error-page.component';
import { MatSnackBarModule } from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  declarations: [
    AppComponent,
    BlockListComponent,
    HeaderComponent,
    FooterComponent,
    InputSearchDirective,
    SearchComponent,
    BlockDetailComponent,
    UniModalComponent,
    AccountDetailComponent,
    TransactionDetailComponent,
    AccountListComponent,
    TokenAmountFormatPipe,
    CurrencyFormatPipe,
    PreloaderComponent,
    ErrorPageComponent
  ],
  entryComponents: [UniModalComponent],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatSnackBarModule,
    UiSwitchModule.forRoot({
      size: 'small',
      color: '#00A690',
      switchColor: '#FFFFFF',
      defaultBgColor: '#BFBEBF',
      defaultBoColor : 'transparent',
    }),
    NgbModule.forRoot()
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
