import { Component, OnInit } from '@angular/core';
import { PreloaderService} from "../../../service/preloader.service";

@Component({
  selector: 'app-preloader',
  templateUrl: './preloader.component.html',
  styleUrls: ['./preloader.component.scss']
})
export class PreloaderComponent implements OnInit {

  preloaderSubscription;
  isLoading: boolean = true;

  constructor(private preloaderService: PreloaderService) { }

  ngOnInit() {
    this.preloaderSubscription = this.preloaderService.load.subscribe((result) => {
      this.isLoading = result;
    });
  }

}
