// Imports
import { NgModule } from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import { BlockListComponent } from './component/block-list/block-list.component';
import { BlockDetailComponent } from './component/block-detail/block-detail.component';
import { AccountListComponent } from './component/account-list/account-list.component';
import { AccountDetailComponent } from './component/account-detail/account-detail.component';
import { TransactionDetailComponent } from './component/transaction-detail/transaction-detail.component';
import { ErrorPageComponent} from "./component/error-page/error-page.component";

// Route Configuration
export const appRoutes: Routes = [
    { path: '', redirectTo: 'blocks', pathMatch: 'full'},
    {
        path : 'blocks',
        component : BlockListComponent,
    },
    {
        path : 'blocks/:id',
        component : BlockDetailComponent,
    },
    {
        path : 'accounts/:name',
        component : AccountDetailComponent,
    },
    {
        path : 'transactions/:id',
        component : TransactionDetailComponent,
    },
    {
      path : 'fatal-error',
      component : ErrorPageComponent,
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes)
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule {

  constructor() {}
}
