import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {PreloaderService} from '../../service/preloader.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UniModalComponent} from '../modal/signature/uni-modal.component';
import {Util} from '../../util';
import {MatSnackBar} from '@angular/material';

declare var sophia: any;

@Component({
  selector: 'app-transaction-detail',
  templateUrl: './transaction-detail.component.html',
  styleUrls: ['./transaction-detail.component.scss']
})
export class TransactionDetailComponent implements OnInit {

  public id: string;
  public error = false;

  public item = {
    ref_block_num: null,
    ref_block_prefix: null,
    expiration: '',
    operations: [],
    extensions: [],
    signatures: [],
    transaction_id: null,
    block_num: null,
    transaction_num: null
  };

  public itemHistory = [];

  constructor(private route: ActivatedRoute,
              private router: Router,
              private preloaderService: PreloaderService,
              private modalService: NgbModal,
              public snackBar: MatSnackBar) {

    this.preloaderService.load.emit(true);

    this.route.params.subscribe(params => {
      this.preloaderService.load.emit(true);
      this.id = params.id;

      sophia.api.getTransaction(this.id, (err, response) => {

        if (err) {
          this.error = true;
        } else {
          console.log('response =>', response);
          Object.assign(this.item, response);
          console.log('item', this.item);
          this.preloaderService.load.emit(false);
          this.error = false;
        }

      });

    });
  }

  ngOnInit() {

  }

  open(data) {
    const modalRef = this.modalService.open(UniModalComponent, { centered: true, size: 'lg' });
    modalRef.componentInstance.data = data;
  }

  copy(text) {
    Util.copyLink(text);
    this.snackBar.open('Copied to clipboard', null, {
      duration: 2000
    });
  }

}
