import { Component, OnInit } from '@angular/core';
import { keyCode } from '../../../../constant/global';
import { SearchService } from '../../../../service/search.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-search',
  template: `
      <div class="input-group my-2 pr-3">
          <input [(ngModel)]="searchValue" type="text" class="form-control shadow"
                      placeholder="Search for Block, Account, Transaction" aria-label="Search for Block, Account, Transaction"
                      aria-describedby="button-addon2" (keydown)="searchOnEnter($event)">
          <div class="input-group-append">
              <button class="btn btn-outline-secondary shadow" type="button" id="button-addon2" (click)="search()">
                <i class="material-icons">search</i></button>
          </div>
      </div>`,
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {

  public searchValue: string;

  constructor(private searchService: SearchService, private router: Router) { }

  ngOnInit() {
  }

  searchOnEnter(event) {
      if (event.keyCode === keyCode.returnKeycode) {
          this.search();
      }
  }

  search() {
      this.searchService.search.emit(this.searchValue);
      console.log('searchValue', this.searchValue);
      this.checkSearchType(this.searchValue);
  }

  checkSearchType(searchValue) {

    if (/^\d+$/.test(searchValue)) {
        console.log('searching in block', searchValue.length);
        this.router.navigate(['blocks', searchValue.replace( /^\D+/g, '')]);
    } else if (searchValue.length !== 40) {
        console.log('searching in account', searchValue.length);
        this.router.navigate(['accounts', `${searchValue}`]);
    } else if (searchValue.length === 40) {
        console.log('search in transactions', searchValue.length);
        this.router.navigate(['transactions', `${searchValue}`]);
    } else {
        console.log('no way bro...');
    }

  }

}
