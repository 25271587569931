import {EventEmitter, Injectable, OnInit, Output} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PreloaderService {

  @Output() load: EventEmitter<boolean> = new EventEmitter();

  constructor() {
  }
}
