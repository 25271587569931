export class Util {

  public static copyLink (text:string) {
    const event = (e: any) => {
      e.clipboardData.setData('text/plain', text);
      e.preventDefault();
      // ...('copy', e), as event is outside scope
      document.removeEventListener('copy', e);
    }
    document.addEventListener('copy', event);
    document.execCommand('copy');
  }

}

